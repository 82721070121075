<script>
  import {
    layoutComputed,
    layoutMethods
  } from "@/state/helpers";
  import Vertical from "./vertical";
  import Horizontal from "./horizontal";
  import $ from "jquery";
  export default {
    components: {
      Vertical,
      Horizontal
    },
    data() {
      return {};
    },
    computed: {
      ...layoutComputed,
    },
    methods: {
      ...layoutMethods,
      changeTitle(title) {
        let indexKey = "/index/apply"
        let projectTitle = "四川省人力资源和社会保障厅人事人才测评服务专栏"
        // 首页特殊处理
        if (this.$route.path === indexKey) {

          document.title = projectTitle
        } else {

          document.title = title + ' · ' + projectTitle
        }
      },
    },
    mounted() {
      let items = [{}, {}];
      let list = [];
      if (this.layoutType == "vertical") {
        list = $("#side-menu").find(".mm-active");
        if (list.length != 0) {
          items[0]["text"] = list[0].innerText.split("\n")[0];
          if (list[2]) {
            items[1]["text"] = list[2].innerText ? list[2].innerText : "";
          }
        } else {
          items = this.$store.state.layout.items;
        }
      } else {
        list = $(".navbar-nav").find(".active");
        if (list.length != 0) {
          items[0]["text"] = list[0].innerText ? list[0].innerText : "";
          if (list[2]) {
            items[1]["text"] = list[2].innerText ? list[2].innerText : "";
          }
        } else {
          items = this.$store.state.layout.items;
        }
      }
      if (items && (items[1].text || items[0])) {
         this.changeTitle(items[1].text ? items[1].text : items[0].text)
      }

      this.changeItems({
        items: items,
      });
    },
    created() {
      let setDataStr = localStorage.getItem("systemSetStyle");
      if (setDataStr) {
        let setData = JSON.parse(setDataStr);
        if (setData.layoutType) {
          this.changeLayoutType({
            layoutType: setData.layoutType,
          });
        }
        if (setData.layoutWidth) {
          this.changeLayoutWidth({
            layoutWidth: setData.layoutWidth,
          });
        }
        if (setData.leftSidebarType) {
          this.changeLeftSidebarType({
            leftSidebarType: setData.leftSidebarType,
          });
        }
        if (setData.topbar) {
          this.changeTopbar({
            topbar: setData.topbar,
          });
        }
      }

      // this.$store.commit("CHANGE_LAYOUT","vertical")
    },
  };
</script>

<template>
  <div>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
