export const menuItems = [{
    id: 1,
    label: 'menuitems.dashboard.text',
    icon: 'icon-waterfalls-h',
    link: '/home'
  },
  {
    id: 13,
    label: 'menuitems.info.text',
    icon: 'icon-user-add-line',
    subItems: [{
        id: 14,
        label: 'menuitems.info.list.base',
        link: '/personalData'


      },
      {
        id: 15,
        label: 'menuitems.info.list.image',
        link: '/personalImage'

      },
      {
        id: 19,
        label: 'menuitems.info.list.education',
        link: '/personalEducation'
      },
      {
        id: 17,
        label: 'menuitems.info.list.work',
        link: '/personalWork'
      },
      {
        id: 18,
        label: 'menuitems.info.list.accout',
        link: '/personalAccount'
      }
    ]
  },
  {
    id: 2,
    label: 'menuitems.examinee.text',
    icon: 'icon-ri-pencil-ruler--line',
    subItems: [{
        id: 3,
        label: 'menuitems.examinee.list.now',
        link: '/recordList'
      }, 
      // {
      //   id: 23,
      //   label: 'menuitems.examinee.list.special',
      //   link: '/specialList'
      // },
      {
        id: 4,
        label: 'menuitems.examinee.list.mine',
        link: '/record'
      },
    ]
  },
  {
    id: 5,
    label: 'menuitems.examinee.list.print',
    icon: 'icon-printer',
    link: '/ticketPrinting'
  },
  {
    id: 6,
    label: 'menuitems.examScore.text',
    icon: 'icon-scroll-paper',
    subItems: [{
        id: 7,
        label: 'menuitems.examScore.list.score',
        link: '/scoreQuery'
      },{
        id: 24,
        label: 'menuitems.examScore.list.recheckScore',
        link: '/recheckScore'
      },
      {
        id: 9,
        label: 'menuitems.examScore.list.review',
        link: '/recordReview'
      },
    ]
  },
  {
    id: 10,
    label: 'menuitems.examZhengshu.text',
    icon: 'icon-truck',
    subItems: [
      // {
      //     id: 11,
      //     label: 'menuitems.examZhengshu.list.send',
      //     link: '/scoreSend'

      // },
      {
        id: 22,
        label: 'menuitems.examZhengshu.list.certificate',
        link: '/certificate'

      },
      {
        id: 12,
        label: 'menuitems.examZhengshu.list.logistics',
        link: '/recordCertificate'

      },


    ]
  },

  {
    id: 19,
    label: 'menuitems.help.text',
    icon: 'icon-question-circle',
    link: '/questionList'
    // subItems: [{
    //         id: 20,
    //         label: 'menuitems.help.list.vertical',
    //         link: './12131213'


    //     },
    //     {
    //         id: 21,
    //         label: 'menuitems.help.list.lighttopbar',
    //         link: './441418564189418'
    //     },
    // ]
  }
]
